import { generateMapByOpts, mapHelper } from 'common/utils'

// 是否出售
const isSellOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '是',
    value: 1
  }, {
    text: '否',
    value: 0
  }
]
const isSellMap = generateMapByOpts(isSellOps)
// 是否出租
const isLeaseOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '是',
    value: 1
  }, {
    text: '否',
    value: 0
  }
]
const isLeaseMap = generateMapByOpts(isLeaseOps)
// 状态
const statusOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '待审核',
    value: 0
  }, {
    text: '未通过',
    value: 2
  }, {
    text: '已通过',
    value: 1
  }, {
    text: '已关闭',
    value: 3
  }
]
// 审核状态
const verifyStatusOps = [
  {
    text: '请选择',
    value: undefined
  }, {
    text: '未通过',
    value: 2
  }, {
    text: '已通过',
    value: 1
  }, {
    text: '已关闭',
    value: 3
  }
]
const statusMap = generateMapByOpts(statusOps)
// 来源
const sourceOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '新增',
    value: 0
  }, {
    text: '修改',
    value: 1
  }
]
const sourceMap = generateMapByOpts(sourceOps)
// 房屋类型
const houseTypeMap = {
  1: '公寓',
  2: '排屋',
  3: '别墅',
  4: '商铺',
  5: '办公用房',
  6: '物业经营用房',
  7: '住宅',
  8: '会所',
  21: '保姆房',
  22: '储藏室',
  23: '自行车库',
  24: '车库',
  25: '车位',
  26: '其它',
  27: '地下室',
  28: '物业管理用房',
  29: '物业其它用房'
}
// 是否虚拟
const isVirtualOps = [
  {
    text: '否',
    value: 0
  }, {
    text: '是',
    value: 1
  }
]
// 使用状态
const usingStatusOps = [
  {
    text: '请选择',
    value: undefined
  }, {
    text: '启用',
    value: 1
  }, {
    text: '停用',
    value: 0
  }
]
// 建筑类型
const buildTypeOps = [
  {
    text: '请选择',
    value: undefined
  }, {
    text: '多层',
    value: 1
  }, {
    text: '小高层',
    value: 2
  }, {
    text: '高层',
    value: 3
  }, {
    text: '排屋',
    value: 4
  }, {
    text: '别墅',
    value: 5
  }, {
    text: '写字楼',
    value: 6
  }, {
    text: '商场',
    value: 7
  }, {
    text: '会所',
    value: 8
  }, {
    text: '酒店',
    value: 9
  }, {
    text: '停车场',
    value: 10
  }, {
    text: '幼儿园',
    value: 11
  }, {
    text: '商业（综合体）',
    value: 12
  }, {
    text: '公共建筑',
    value: 13
  }, {
    text: '标准办公（超甲级）',
    value: 16
  }, {
    text: '标准办公（甲级）',
    value: 17
  }, {
    text: '标准办公（乙级）',
    value: 18
  }, {
    text: '园区办公（联合）',
    value: 19
  }, {
    text: '独栋办公（<1万方）',
    value: 20
  }, {
    text: '住宅（公寓）',
    value: 21
  }, {
    text: '厂房',
    value: 22
  }, {
    text: '其它',
    value: 15
  }
]

// 房屋状态
const roomStatus = [
  {
    text: '空置',
    value: 1
  },
  {
    text: '未领',
    value: 2
  },
  {
    text: '空关',
    value: 3
  },
  {
    text: '装修',
    value: 4
  },
  {
    text: '入住',
    value: 5
  }
]

const {
  map: roomStatusMap,
  setOps: roomStatusOps
} = mapHelper.setMap(roomStatus)

// 是否可售
const saleType = [
  {
    text: '可出售',
    value: 1
  },
  {
    text: '不可出售',
    value: 0
  }
]

const {
  map: saleTypeMap,
  setOps: saleTypeOps
} = mapHelper.setMap(saleType)

// 出售状态
const saleStatus = [
  {
    text: '未出售',
    value: 0
  },
  {
    text: '售卖中',
    value: 2
  },
  {
    text: '出售锁定',
    value: 3
  },
  {
    text: '已出售',
    value: 1
  }
]

const {
  map: saleStatusMap,
  setOps: saleStatusOps
} = mapHelper.setMap(saleStatus)

// 是否出租
const rentType = [
  {
    text: '可出租',
    value: 1
  },
  {
    text: '不可出租',
    value: 0
  }
]

const {
  map: rentTypeMap,
  setOps: rentTypeOps
} = mapHelper.setMap(rentType)

// 出租状态
const rentStatus = [
  {
    text: '未出租',
    value: 0
  },
  {
    text: '招商中',
    value: 2
  },
  {
    text: '出租锁定',
    value: 3
  },
  {
    text: '已出租',
    value: 1
  }
]

const {
  map: rentStatusMap,
  setOps: rentStatusOps
} = mapHelper.setMap(rentStatus)

// 租金单位
const leaseUnit = [
  {
    text: '元/㎡/天',
    value: 1
  },
  {
    text: '元/㎡/月',
    value: 2
  },
  {
    text: '元/月',
    value: 3
  }
]

const {
  map: leaseUnitMap,
  setOps: leaseUnitOps
} = mapHelper.setMap(leaseUnit)

// 是否是服务中心
const isServerCenter = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

const {
  map: isServerCenterMap,
  setOps: isServerCenterOps
} = mapHelper.setMap(isServerCenter)

// 是否关联第三方房号
const isHaveThirdhouse = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]
const {
  map: isHaveThirdhouseMap,
  setOps: isHaveThirdhouseOps
} = mapHelper.setMap(isHaveThirdhouse)

export {
  isSellOps,
  isSellMap,
  isLeaseOps,
  isLeaseMap,
  roomStatusOps,
  roomStatusMap,
  statusOps,
  statusMap,
  sourceOps,
  sourceMap,
  houseTypeMap,
  isVirtualOps,
  usingStatusOps,
  buildTypeOps,
  verifyStatusOps,
  // new add
  saleTypeMap,
  saleTypeOps,
  saleStatusMap,
  saleStatusOps,
  rentTypeMap,
  rentTypeOps,
  rentStatusMap,
  rentStatusOps,
  leaseUnitMap,
  leaseUnitOps,
  isServerCenterMap,
  isServerCenterOps,
  isHaveThirdhouseMap,
  isHaveThirdhouseOps
}
