<template>
  <div class="houseAuditList-wrapper">
    <list ref="list" exportPermission="export" :searchUrl="searchUrl" :exportUrl="exportUrl"
      :searchParams.sync="searchParams" :headers="headers" :isMultiSelect="true" :selectable="selectable">
      <template #headerSlot>
        <v-button text="新增房号" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select label="房屋类型" v-model="searchParams.houseType" :options="houseTypeOps"></v-select>
        <v-select label="是否可售" v-model="searchParams.canSell" :options="saleTypeOps"></v-select>
        <v-select label="出售状态" v-model="searchParams.isSell" :options="saleStatusOps"></v-select>
        <v-select label="是否可租" v-model="searchParams.canLease" :options="rentTypeOps"></v-select>
        <v-select label="出租状态" v-model="searchParams.isLease" :options="rentStatusOps"></v-select>
        <v-select label="房屋状态" v-model="searchParams.roomStatus" :options="roomStatusOps"></v-select>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-select label="来源" v-model="searchParams.source" :options="sourceOps"></v-select>
        <v-input label="苑" v-model="searchParams.blockName"></v-input>
        <v-input label="幢" v-model="searchParams.buildingNumber"></v-input>
        <v-input label="单元" v-model="searchParams.unit"></v-input>
        <v-input label="室" v-model="searchParams.room"></v-input>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="tenantParams"></v-select2>
        <v-datepicker label="修改时间段" :startTime.sync="searchParams.lastUpdateTimeStart"
          :endTime.sync="searchParams.lastUpdateTimeEnd" type="rangedatetimer"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="scope.row.status != 1" text="编辑" type="text" permission="update"
          @click="edit(scope.row)"></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量通过" permission="update" :disabled="scope.selectedData.ids.length === 0"
          @click="batch(scope.selectedData, 1)"></v-button>
        <v-button text="批量拒绝" permission="update" :disabled="scope.selectedData.ids.length === 0"
          @click="batch(scope.selectedData, 2)"></v-button>
      </template>
    </list>
    <el-dialog title="审核备注" :visible.sync="operaDialog" width="576px" class="dialog" @close="closeDialog">
      <el-form ref="form" :model="form" :rules="rules" label-suffix="：" size="medium">
        <el-form-item :rules="[{ required: true, message: '请输入备注', trigger: 'blur' }]" prop="taskMemo">
          <v-textarea v-model="form.taskMemo" :width="536"></v-textarea>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <v-button type="" @click="operaDialog = false" text="关 闭"></v-button>
        <v-button type="primary" @click="submit" text="保 存"></v-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  batchURL
  // getCommunityRommTypesURL
} from './api'
import {
  roomStatusOps,
  roomStatusMap,
  statusOps,
  statusMap,
  sourceOps,
  sourceMap,
  houseTypeMap,
  saleTypeMap,
  saleTypeOps,
  saleStatusMap,
  saleStatusOps,
  rentTypeMap,
  rentTypeOps,
  rentStatusMap,
  rentStatusOps
} from './map'
import { communityParams, tenantParams } from 'common/select2Params'
import { getHouseTypeData } from 'common/networkData'

export default {
  name: 'HouseAuditList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      roomStatusOps: roomStatusOps(1),
      statusOps,
      sourceOps,
      saleTypeOps: saleTypeOps(1),
      saleStatusOps: saleStatusOps(1),
      rentTypeOps: rentTypeOps(1),
      rentStatusOps: rentStatusOps(1),
      houseTypeOps: [],
      communityParams,
      tenantParams,
      searchParams: {
        code: '',
        communityId: '',
        houseType: undefined,
        roomStatus: undefined,
        status: undefined,
        source: undefined,
        blockName: '',
        buildingNumber: '',
        unit: '',
        room: '',
        regionId: '',
        lastUpdateTimeStart: '',
        lastUpdateTimeEnd: '',
        canSell: undefined,
        isSell: undefined,
        canLease: undefined,
        isLease: undefined,
        isServerCenter: undefined
      },
      headers: [
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'houseTypeText',
          label: '房屋类型',
          formatter (row) {
            return houseTypeMap[row.houseType]
          }
        },
        {
          prop: 'blockName',
          label: '苑'
        },
        {
          prop: 'buildingNumber',
          label: '幢'
        },
        {
          prop: 'unit',
          label: '单元'
        },
        {
          prop: 'room',
          label: '室'
        },
        {
          prop: 'saleTypeText',
          label: '是否可售',
          formatter (row) {
            return saleTypeMap[row.canSell]
          }
        },
        {
          prop: 'saleStatusText',
          label: '出售状态',
          formatter (row) {
            return saleStatusMap[row.isSell]
          }
        },
        {
          prop: 'rentTypeText',
          label: '是否可租',
          formatter (row) {
            return rentTypeMap[row.canLease]
          }
        },
        {
          prop: 'rentStatusText',
          label: '出租状态',
          formatter (row) {
            return rentStatusMap[row.isLease]
          }
        },
        {
          prop: 'userNameText',
          label: '房屋状态',
          formatter (row) {
            return roomStatusMap[row.roomStatus]
          }
        },
        {
          prop: 'sourceText',
          label: '来源',
          formatter (row) {
            return sourceMap[row.source]
          }
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'statusText',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        }
      ],
      rules: {},
      selected: [],
      operaDialog: false,
      form: {
        taskMemo: ''
      },
      putHeader: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      }
    }
  },
  mounted () {
    this.getCommunityRommTypes()
    const { status } = this.$route.query
    if (status !== undefined) {
      this.searchParams = {
        code: '',
        communityId: '',
        houseType: undefined,
        roomStatus: undefined,
        status: undefined,
        source: undefined,
        blockName: '',
        buildingNumber: '',
        unit: '',
        room: '',
        regionId: '',
        lastUpdateTimeStart: '',
        lastUpdateTimeEnd: '',
        canSell: undefined,
        isSell: undefined,
        canLease: undefined,
        isLease: undefined
      }
      this.searchParams.status = status - 0
    }
  },
  methods: {
    selectable (row, index) {
      return row.status === 0
    },
    async getCommunityRommTypes () {
      const { houseTypeOps } = await getHouseTypeData()
      this.houseTypeOps = houseTypeOps(1)
    },
    async updateStatus (selected, status, taskMemo) {
      let ids = selected.ids
      let isOk = await this.$confirm('确定执行该操作？')
      if (isOk) {
        let dataObjects = []
        selected.data.forEach(item => {
          let blockName = (item.blockName) ? '-' + item.blockName : ''
          let buildingNumber = (item.buildingNumber) ? '-' + item.buildingNumber : ''
          let unit = (item.unit) ? '-' + item.unit : ''
          let room = (item.room) ? '-' + item.room : ''
          dataObjects.push(`${item.communityName}${blockName}${buildingNumber}${unit}${room}`)
        })

        let _this = this
        let _ids = ids.join(',')
        let postData = {
          ids: _ids,
          dataObject: dataObjects.join(';'),
          status
        }
        taskMemo && (postData.taskMemo = taskMemo)
        _this.$axios.put(batchURL, this.$qs.stringify(postData), this.putHeader).then(res => {
          if (res.status === 100) {
            _this.$refs.list.searchData()
            this.operaDialog = false
            _this.$message({
              type: 'success',
              message: '操作成功',
              center: true
            })
          }
        })
      }
    },
    batch (selected, status) {
      this.selected = selected
      if (status === 1) {
        this.updateStatus(this.selected, status)
      } else if (status === 2) {
        this.operaDialog = true
      }
    },
    edit (row) {
      this.$router.push({
        name: 'houseAuditForm',
        query: {
          id: row.id
        }
      })
    },
    create () {
      this.$router.push({
        name: 'validHouseForm'
      })
    },
    submit () {
      this.$refs.form.validate((result, object) => {
        if (result) {
          this.updateStatus(this.selected, 2, this.form.taskMemo)
        }
      })
    },
    closeDialog () {
      this.$refs.form.resetFields()
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog {
  text-align: left;

  .text {
    margin-bottom: 20px;
  }
}

.dialog-footer {
  .v-button-container {
    margin-left: 10px;
  }
}

.v-textarea-container {
  width: 100%;
}
</style>
