// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}communityRoomNumAudit/listCommunityRoomRumAudit`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}communityRoomNumAudit/exportDelayCommunityRoomList`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 批量操作
const batchURL = `${API_CONFIG.butlerBaseURL}communityRoomNumAudit/passRoomAuditBatch`
// 表单更新
const passRoomAuditURL = `${API_CONFIG.butlerBaseURL}communityRoomNumAudit/passRoomAudit`
// 获取房屋类型
// const getCommunityRommTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getCommunityRommTypes.action`
// 获取房屋详情
const getRoomAuditInfoURL = `${API_CONFIG.butlerBaseURL}communityRoomNumAudit/getRoomAuditInfo`
// 获取苑列表
// const queryBlockNameFromCommunityURL = `${API_CONFIG.baseURL}serverRoomAction!queryBlockNameFromCommunity.action`

// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=user`
// 获取房号的租金单位类型
const getCommunityRoomNumLeaseUnitURL = `${API_CONFIG.butlerBaseURL}communityRoomNumAudit/getCommunityRoomNumLeaseUnit`

export {
  getListURL,
  exportListURL,
  getOrgListURL,
  batchURL,
  passRoomAuditURL,
  // getCommunityRommTypesURL,
  getRoomAuditInfoURL,
  // queryBlockNameFromCommunityURL,
  uploadURL,
  getCommunityRoomNumLeaseUnitURL
}
